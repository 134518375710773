import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import i18n from "i18next";

import Links from "../../content/Links.json";

import {
  FooterSection,
  Title,
  Extra,
  NavLink,
  Para,
  CopyrightText,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  const locales = ["en", "es", "fr", "ru", "de", "ja"];

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col xs={24} sm={8} md={4}>
              <Title>{t("id_footer_company")}</Title>
              <Para>GooseMaps Ltd.</Para>
            </Col>
            <Col xs={24} sm={8} md={4}>
              <Title>{t("id_footer_legal")}</Title>
              <NavLink left="true" to="/terms_of_use">
                {t("id_footer_terms_of_use")}
              </NavLink>
              <NavLink left="true" to="/privacy_policy">
                {t("id_footer_privacy_policy")}
              </NavLink>
            </Col>
            <Col xs={24} sm={8} md={4}>
              <Title>{t("id_footer_language")}</Title>
              <LanguageSwitchContainer>
                <Row justify="space-between">
                  {locales.map((locale) => {
                    return <Col xs={6} md={4} style={{ margin: "0.5rem" }} key={`key_locale_${locale}`}>
                      <LanguageSwitch onClick={() => handleChange(locale)}>
                        <SvgIcon
                          src={`${locale}.svg`}
                          aria-label="homepage"
                          width="30px"
                          height="30px"
                        />
                      </LanguageSwitch>
                    </Col>;
                  })}
                </Row>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row justify="space-between" style={{paddingTop: "1rem"}}>
            <Col>
              <SocialLink
                  href={Links.facebook}
                  src="facebook.svg"
                />
            </Col>
            <Col>
              <SocialLink
                href={Links.twitter}
                src="twitter.svg"
              />
            </Col>
            <Col>
              <SocialLink
                href={Links.instagram}
                src="instagram.svg"
              />
            </Col>
          </Row>
          <Row justify="space-between" style={{paddingTop: "2rem"}}>
            <Col>
              <CopyrightText>
                {t("id_footer_copyright")}
              </CopyrightText>
            </Col>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
